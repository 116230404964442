import { Button, Input, Modal, Typography } from "ritual-ui";
import styles from "./styles.module.scss";
import Search from "../../../../assets/icons/Search.tsx";
import { Member } from "../../../../api/types.ts";
import { useGetMembersResolver } from "../../../../api/resolvers/memberResolvers.tsx";
import { useEffect, useState } from "react";
import Loader from "../../../../components/base/Loader";
import MemberView from "./components/MemberView.tsx";

export interface RecommendContentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (member: Member) => void;
  isLoading: boolean;
  selectedMember?: Member;
  onSelectMember: (member: Member) => void;
}

const RecommendContentModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  selectedMember,
  onSelectMember,
}: RecommendContentModalProps) => {
  const { data: fetchedMembers, isLoading: isMembersLoading } =
    useGetMembersResolver();
  const [query, setQuery] = useState("");
  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    if (fetchedMembers) {
      setMembers(fetchedMembers);
    }
  }, [fetchedMembers]);

  useEffect(() => {
    if (fetchedMembers) {
      setMembers(
        fetchedMembers.filter((member) =>
          `${member.first_name} ${member.last_name}`
            .toLowerCase()
            .includes(query.toLowerCase()),
        ),
      );
    }
  }, [query]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          width: "1000px",
        },
      }}
    >
      <div className={styles.wrapper}>
        <Typography
          variant="title"
          style={{ textAlign: "center", marginBottom: "24px" }}
          weight={500}
        >
          Recommend Content
        </Typography>
        <Typography weight={500} color="#1F2222">
          Select the member (one at a time) for whom you'd like to recommend
          content.
        </Typography>

        <div className={styles.search_container}>
          <Search className={styles.search_icon} />
          <Input
            className={styles.search}
            onChange={(value) => setQuery(value)}
            id="search"
            placeholder="Search by name"
          />
        </div>

        <div className={styles.members}>
          {isMembersLoading ? (
            <Loader />
          ) : members.length ? (
            members?.map((member) => (
              <MemberView
                member={member}
                onClick={() => onSelectMember(member)}
                isChecked={selectedMember?.email === member.email}
              />
            ))
          ) : (
            <Typography
              weight={500}
              color="#1F2222"
              style={{ textAlign: "center" }}
            >
              No members found.
            </Typography>
          )}
        </div>

        <div className={styles.button_container}>
          <Button id="Back" variant="outlined" onClick={onClose}>
            Close
          </Button>
          <Button
            id="Share"
            variant="large"
            onClick={() => selectedMember && onSubmit(selectedMember)}
            loading={isLoading}
          >
            {isLoading ? "Processing..." : "Continue"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RecommendContentModal;
