import { Navigate, createBrowserRouter } from "react-router-dom";
import { Home, Login, Support, Unauthorized } from "src/pages";
import ProtectedRoute from "./ProtectedRoute";
import { Routes } from "./routes";
import ContentForMembers from "../pages/ContentForMembers";
import { MainLayout } from "src/entities/MainLayout";
import PageLayout from "src/components/layout/PageLayout";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: <PageLayout />,
        children: [
          { path: Routes.HOME, element: <Home /> },
          { path: Routes.SUPPORT, element: <Support /> },
          { path: Routes.CONTENT_FOR_MEMBERS, element: <ContentForMembers /> },
        ],
      },
    ],
  },
  {
    path: Routes.MEMBERS,
    element: <ProtectedRoute />,
    children: [{ path: Routes.MEMBERS, element: <MainLayout /> }],
  },
  { path: Routes.LOGIN, element: <Login /> },
  { path: Routes.UNAUTHORIZED_ROUTE, element: <Unauthorized /> },
  {
    path: "*",
    element: <Navigate to={Routes.HOME} />,
  },
]);
