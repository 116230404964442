import React, { useEffect, useState } from "react";
import { ExpertContentRecommendationPopup } from "ritual-ui/dist/components/composite/ExpertContentRecommendationPopup";
import {
  ExpertContentRecommendationPopupType,
  ExpertContentRecommendationType,
  Recommendation,
  SelectType,
} from "../types";
import {
  Expert,
  ExpertSharedRecommendation,
  Member,
} from "../../../../../api/types.ts";
import {
  useGetFormatTagQuery,
  useGetLibraryContentQuery,
  useGetSkillTagQuery,
  useGetTopicTagQuery,
} from "../../../../../api/instances/cms.ts";
import { useLazyGetMemberSharedRecommendationsQuery } from "../../../../../api/instances/management.ts";
import { useContentRecommendationFilter } from "../../../../../hooks/useContentRecommendationFilter.ts";

interface SelectContentToRecommendPopupProps {
  isOpen: boolean;
  member: Member;
  expert?: Expert | null;
  selected: Recommendation[];
  setSelected: (value: React.SetStateAction<Recommendation[]>) => void;
  isLoading: boolean;
  type: ExpertContentRecommendationPopupType;
  onTypeChange: (type: ExpertContentRecommendationPopupType) => void;
  onClose: () => void;
  onSubmit: () => void;
  secondaryButton: { title: string; onClick: () => void };
}

const SelectContentToRecommendPopup = ({
  member,
  expert,
  setSelected,
  isLoading,
  type,
  isOpen,
  onTypeChange,
  onClose,
  onSubmit,
  selected,
  secondaryButton,
}: SelectContentToRecommendPopupProps) => {
  const { data: formats } = useGetFormatTagQuery();
  const { data: skills } = useGetSkillTagQuery();
  const { data: topics } = useGetTopicTagQuery();
  const { data: libraryContent } = useGetLibraryContentQuery();
  const [getSharedContent] = useLazyGetMemberSharedRecommendationsQuery();
  const { filterChange, clearFilters } = useContentRecommendationFilter();
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [sharedContent, setSharedContent] =
    useState<ExpertSharedRecommendation[]>();

  useEffect(() => {
    if (libraryContent && sharedContent) {
      setContentSharedInRecommendations(libraryContent);
    }
  }, [libraryContent, sharedContent]);

  const getSharedContentHandler = async () => {
    if (member && expert) {
      const sharedContent = await getSharedContent({
        expertId: expert.uuid,
        memberId: member.uuid,
      }).unwrap();

      setSharedContent(sharedContent);
    }
  };

  useEffect(() => {
    getSharedContentHandler();
  }, [member, expert]);

  const onSelectedChange = (value: Recommendation) => {
    setSelected((prev) => {
      const isAlreadySelected = prev.some((rec) => rec.id === value.id);

      if (isAlreadySelected) {
        return prev.filter((rec) => rec.id !== value.id);
      } else {
        return [...prev, value];
      }
    });
  };

  const onFilterSelect = (values: { filter: unknown; type: SelectType }) => {
    const filteredRecommendations = filterChange(values);

    setContentSharedInRecommendations(filteredRecommendations);
  };

  const setContentSharedInRecommendations = (
    recommendations?: Recommendation[],
  ) => {
    setRecommendations(
      recommendations?.map((content) => ({
        ...content,
        contentShared: !!sharedContent?.find(
          (shared) => +shared.content_cms_id === content.id,
        ),
      })) as Recommendation[],
    );
  };

  const onClearFiltersClick = () => {
    clearFilters();

    setRecommendations(libraryContent as Recommendation[]);
  };

  return (
    <>
      {topics && skills && formats && (
        <ExpertContentRecommendationPopup
          recommendationReceiver={member?.first_name + " " + member?.last_name}
          topics={topics}
          skills={skills}
          formats={formats}
          recommendations={
            type === ExpertContentRecommendationType.REVIEW
              ? selected
              : recommendations
          }
          isOpen={isOpen}
          onClose={onClose}
          type={type}
          selectedValues={selected}
          onSelect={onSelectedChange}
          onSubmit={onSubmit}
          onTypeChange={onTypeChange}
          isLoading={isLoading}
          onFilterSelect={onFilterSelect}
          onClearFilters={onClearFiltersClick}
          secondaryButton={secondaryButton}
          loadMoreRecommendations={() => {
            // TODO: load more recommendations
          }}
        />
      )}
    </>
  );
};

export default SelectContentToRecommendPopup;
