import { ContentStates } from "src/entities/MainLayout/types";

export const mainSectionTabs = [
  { key: ContentStates.GROWTHWORK, name: "Growthwork" },
  { key: ContentStates.EMOTIONS, name: "Emotions", ff: "emotionalCheckIn" },
  { key: ContentStates.CHAT, name: "Chat" },
];

export const additionalSectionTabs = [
  { key: ContentStates.NOTES, name: "Notes" },
  { key: ContentStates.PROFILE, name: "Profile" },
];
