import { Navigate, Outlet } from "react-router-dom";
import { Routes } from "./routes";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorLayout, Loader } from "src/components";
import { useGetTokenState } from "src/store/tokenReducer";
import { useGetExpertQueryResolver } from "src/api/resolvers/expertResolvers";

const ProtectedRoute = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { token } = useGetTokenState();
  const isTokenLoading = isAuthenticated && !token;
  const { isLoading: isExpertLoading, isError } = useGetExpertQueryResolver();

  if (isLoading || isTokenLoading || isExpertLoading) return <Loader />;

  if (!isAuthenticated) return <Navigate to={Routes.LOGIN} />;

  if (isError) {
    return <ErrorLayout />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
