import { SuccessModal } from "ritual-ui/dist/components/composite/SuccessModal";
import { Member } from "../../../../../api/types.ts";

interface ShareContentWithPartnerModalProps {
  isOpen: boolean;
  member: Member;
  onSelectContentClick: () => void;
  onClose: () => void;
}

const ShareContentWithPartnerPopup = ({
  isOpen,
  member,
  onSelectContentClick,
  onClose,
}: ShareContentWithPartnerModalProps) => {
  return (
    <SuccessModal
      isOpen={isOpen}
      title={`Recommendation Shared with ${member?.first_name} ${member?.last_name}`}
      description={`Would you like to share different content with ${member?.first_name} ${member?.last_name} partner, ${member?.partner?.first_name} ${member?.partner?.last_name}?`}
      actionButton={{
        title: "Select Content",
        onClick: onSelectContentClick,
      }}
      secondaryButton={{
        title: "Not Right Now",
        onClick: onClose,
      }}
    />
  );
};

export default ShareContentWithPartnerPopup;
