import { ContentStates } from "../../types";

export const Content = ({ contentState }: { contentState: ContentStates }) => {
  switch (contentState) {
    case ContentStates.GROWTHWORK:
      return <div>Growthwork</div>;
    case ContentStates.EMOTIONS:
      return <div>Emotions</div>;
    case ContentStates.CHAT:
      return <div>Chat</div>;
    case ContentStates.NOTES:
      return <div>Notes</div>;
    case ContentStates.PROFILE:
      return <div>Profile</div>;
    default:
      return <></>;
  }
};
