import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQuery from "../utils/fetchBaseQuery";
import { cmsUrls } from "../urls";
import {
  CMSResponseType,
  LibraryContent,
  NotificationContent,
  Recommendation,
  SupportTopic,
} from "../types";
import {
  FiltersSelectValueType,
  transformTag,
} from "../../utils/transformTag.ts";

const transformCmsResponse = <T>(res: CMSResponseType<T, true>) => {
  return res.data.map(({ attributes, id }) => ({ ...attributes, id }));
};

export const cmsApi = createApi({
  reducerPath: "cmsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.CMS_URL,
  }),
  endpoints: (builder) => ({
    getSupportTopics: builder.query<SupportTopic[], void>({
      query: cmsUrls.supportTopics,
      transformResponse: (res: CMSResponseType<SupportTopic, true>) =>
        transformCmsResponse(res),
    }),

    getNotificationContent: builder.query<NotificationContent, string>({
      query: cmsUrls.notificationContent,
      transformResponse: (res: CMSResponseType<NotificationContent, false>) =>
        res.data.attributes,
    }),

    getFormatTag: builder.query<FiltersSelectValueType[], void>({
      query: cmsUrls.formatTag,
      transformResponse: (res: CMSResponseType<{ name: string }, true>) => {
        const response = transformCmsResponse(res);
        return transformTag(response);
      },
    }),

    getSkillTag: builder.query<FiltersSelectValueType[], void>({
      query: cmsUrls.skillTag,
      transformResponse: (res: CMSResponseType<{ title: string }, true>) => {
        const response = transformCmsResponse(res);
        return transformTag(response);
      },
    }),

    getTopicTag: builder.query<FiltersSelectValueType[], void>({
      query: cmsUrls.topicTag,
      transformResponse: (res: CMSResponseType<{ name: string }, true>) => {
        const response = transformCmsResponse(res);
        return transformTag(response);
      },
    }),

    getLibraryContent: builder.query<Recommendation[], void>({
      query: cmsUrls.libraryContent,
      transformResponse: (res: CMSResponseType<LibraryContent, true>) => {
        const response = transformCmsResponse(res);
        return response.map(
          ({
            id,
            title,
            description,
            link,
            topics,
            format_tags,
            skill_tags,
            format,
            content_tags,
            thumbnail,
          }) => {
            const image =
              new URL(process.env.CMS_URL || "").origin +
              thumbnail.data.attributes.url;
            new Image().src = image;
            return {
              id,
              title,
              description,
              contentUrl: link,
              topic: topics?.data?.[0]?.attributes?.title,
              format: format_tags?.data?.[0]?.attributes?.name || format,
              skills: skill_tags?.data?.map(
                ({ attributes }) => attributes?.name,
              ),
              contentShared: false,
              imageUrl: image,
              tags: content_tags?.data?.map(
                ({ attributes }: { attributes: { name: string } }) =>
                  attributes?.name,
              ),
            };
          },
        );
      },
    }),
  }),
});

export const {
  useGetSupportTopicsQuery,
  useGetNotificationContentQuery,
  useGetTopicTagQuery,
  useGetFormatTagQuery,
  useGetSkillTagQuery,
  useGetLibraryContentQuery,
} = cmsApi;
