import {
  useGetFormatTagQuery,
  useGetSkillTagQuery,
  useGetTopicTagQuery,
} from "../../../../api/instances/cms.ts";
import styles from "./styles.module.scss";
import { CustomSelect } from "ritual-ui/dist/components/Select";
import { useEffect, useState } from "react";
import { FiltersType } from "../ExpertContentRecommendationContent/types";
import { DEFAULT_FILTER } from "../../../../utils/transformTag.ts";

export enum SelectType {
  TOPIC = "topic",
  SKILLS = "skills",
  FORMAT = "format",
}

interface FiltersProps {
  selectedFilters: FiltersType[];
  onFilterSelect: (values: { filter: unknown; type: SelectType }) => void;
}

const Filters = ({ onFilterSelect, selectedFilters }: FiltersProps) => {
  const { data: formats } = useGetFormatTagQuery();
  const { data: skills } = useGetSkillTagQuery();
  const { data: topics } = useGetTopicTagQuery();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filters = [
    {
      options: formats,
      label: "Format",
      onChange: (values?: unknown) =>
        onFilterSelect({ filter: values, type: SelectType.FORMAT }),
    },
    {
      options: topics,
      label: "Topic",
      onChange: (values?: unknown) =>
        onFilterSelect({ filter: values, type: SelectType.TOPIC }),
    },
    {
      options: skills,
      label: "Skill",
      onChange: (values?: unknown) =>
        onFilterSelect({ filter: values, type: SelectType.SKILLS }),
    },
  ];

  const isFiltersReset = selectedFilters.every(
    (filter) => filter.value === "All",
  );

  return (
    topics &&
    formats &&
    skills && (
      <div className={styles.filters}>
        {filters.map(
          ({ options, label, onChange }) =>
            options && (
              <CustomSelect
                options={options}
                label={label}
                onChange={onChange}
                value={isFiltersReset ? DEFAULT_FILTER : undefined}
                style={{ control: { width: width > 1000 ? "230px" : "150px" } }}
              />
            ),
        )}
      </div>
    )
  );
};

export default Filters;
