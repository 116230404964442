import { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ContentStates } from "../../types";
import styles from "./styles.module.scss";

interface TabsProps {
  tabs: { ff?: string; key: ContentStates; name: string }[];
  activeTab: string;
  setTab: (tab: ContentStates) => void;
}

export const Tabs = ({ tabs, activeTab, setTab }: TabsProps) => {
  const featureFlags = useFlags();
  const [underlineStyle, setUnderlineStyle] = useState<
    { left: number; width: number } | undefined
  >();

  useEffect(() => {
    const activeTabElement = document.getElementById(activeTab);
    if (activeTabElement) {
      setUnderlineStyle({
        left: activeTabElement.offsetLeft,
        width: activeTabElement.offsetWidth,
      });
    }
  }, [activeTab]);

  return (
    <div className={styles.container}>
      {tabs
        .filter(({ ff }) => !ff || featureFlags[ff])
        .map((tab) => (
          <div
            className={styles.tab}
            id={tab.key}
            key={tab.key}
            onClick={() => {
              setTab(tab.key);
            }}
          >
            {tab.name}
          </div>
        ))}
      <div className={styles.underline} style={underlineStyle} />
    </div>
  );
};
