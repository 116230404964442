import { useState } from "react";
import { Tabs } from "./components/Tabs";
import {
  additionalSectionTabs,
  mainSectionTabs,
} from "./components/Tabs/utils/tabs";
import styles from "./styles.module.scss";
import { ContentStates } from "./types";
import { Content } from "./components/Content";
import { Header } from "./components/Header";

export const MainLayout = () => {
  const [activeMainTab, setActiveMainTab] = useState(ContentStates.GROWTHWORK);
  const [activeAdditionalTab, setActiveAdditionalTab] = useState(
    ContentStates.PROFILE
  );

  return (
    <div className={styles.layout}>
      <Header>
        <Tabs
          setTab={setActiveMainTab}
          tabs={mainSectionTabs}
          activeTab={activeMainTab}
        />
        <Tabs
          setTab={setActiveAdditionalTab}
          tabs={additionalSectionTabs}
          activeTab={activeAdditionalTab}
        />
      </Header>
      <div className={styles.content_wrapper}>
        <div className={styles.sidebar} />
        <div className={styles.section}>
          <Content contentState={activeMainTab} />
        </div>
        <div className={styles.section}>
          <Content contentState={activeAdditionalTab} />
        </div>
      </div>
    </div>
  );
};
