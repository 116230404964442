import Header from "../Header";
import styles from "./styles.module.scss";
import Sidebar from "../Sidebar";
import { useLocation } from "react-router-dom";
import { stylesByPage } from "./utils/styleByPage";
import { Outlet } from "react-router-dom";

const PageLayout = () => {
  const { pathname } = useLocation();

  return (
    <div className={styles.page} style={stylesByPage[pathname]}>
      <Header />
      <div className={styles.content}>
        <Sidebar />
        <div className={styles.container}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
