import styles from "../styles.module.scss";
import { getFirstLetters } from "../../../helpers/getFirstLetters.ts";
import { Typography } from "ritual-ui";
import { Checkbox } from "ritual-ui/dist/components/Checkbox";
import { Member } from "../../../../../api/types.ts";
import { getUserPicture } from "../../../helpers/avatarColors.ts";

interface MemberViewProps {
  member: Member;
  onClick: () => void;
  isChecked: boolean;
}

const MemberView = ({ member, onClick, isChecked }: MemberViewProps) => {
  const userPicture = getUserPicture(
    `${member.first_name} ${member.last_name}`,
  );

  return (
    <div className={styles.member} onClick={onClick}>
      <div className={styles.member_info_container}>
        <div
          className={styles.avatar}
          style={{
            backgroundColor: userPicture.background,
            color: userPicture.color,
          }}
        >
          {getFirstLetters(member.first_name, member.last_name)}
        </div>
        <div className={styles.info}>
          <Typography variant="subtitle" style={{ fontSize: 16 }} weight={600}>
            {`${member.first_name} ${member.last_name}`}
          </Typography>
          <Typography variant="body">{member.email}</Typography>
        </div>
      </div>

      <Checkbox isChecked={isChecked} />
    </div>
  );
};

export default MemberView;
