import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <circle cx={11} cy={11} r={7} stroke="#C4C4C4" />
    <path stroke="#C4C4C4" d="m16 16 5 5" />
  </svg>
);
export default SvgComponent;
