import {
  FILTERS,
  FiltersType,
  SelectType,
} from "../pages/ContentForMembers/components/ExpertContentRecommendationContent/types";
import { OptionType } from "ritual-ui/dist/types/expert-recommendation";
import { useState } from "react";
import { useGetLibraryContentQuery } from "../api/instances/cms.ts";

export const useContentRecommendationFilter = () => {
  const [selectedFilters, setSelectedFilters] =
    useState<FiltersType[]>(FILTERS);
  const { data: libraryContent } = useGetLibraryContentQuery();

  const filterChange = ({
    filter,
    type,
  }: {
    filter: unknown;
    type: SelectType;
  }) => {
    const value = (filter as OptionType).value;

    const newSelectedFilters = selectedFilters.map((selectedFilter) =>
      selectedFilter.type === type
        ? {
            ...selectedFilter,
            value:
              selectedFilter.value === value ? selectedFilter.value : value,
          }
        : selectedFilter,
    );

    const filteredRecommendations = libraryContent?.filter((rec) => {
      return newSelectedFilters.every(
        (newFilter) =>
          (Array.isArray(rec[newFilter.type])
            ? rec[newFilter.type].includes(newFilter.value) ||
              rec.tags.includes(newFilter.value)
            : rec[newFilter.type] === newFilter.value) ||
          newFilter.value === "All",
      );
    });

    setSelectedFilters(newSelectedFilters);

    return filteredRecommendations;
  };

  const clearFilters = () => {
    setSelectedFilters(FILTERS);
  };

  return { selectedFilters, filterChange, clearFilters };
};
