import { ExpertRecommendationMemberShareWithModal } from "ritual-ui/dist/components/composite/ExpertRecommendationMemberShareWithModal";
import { ShareType } from "../types";
import { Member } from "../../../../../api/types.ts";

export interface ExpertRecommendationShareWithContentProps {
  isOpen: boolean;
  onClose: () => void;
  member: Member;
  isLoading: boolean;
  onShareWithPartnerSubmit: () => void;
  onSelectChange: (value: unknown) => void;
}

const WhoShareWithExpertContentPopup = ({
  isOpen,
  isLoading,
  member,
  onClose,
  onShareWithPartnerSubmit,
  onSelectChange,
}: ExpertRecommendationShareWithContentProps) => {
  const options = [
    { value: ShareType.BOTH, label: "Both partners" },
    {
      value: ShareType.PRIMARY,
      label: `${member?.first_name} ${member?.last_name}`,
    },
    {
      value: ShareType.SECONDARY,
      label: `${member?.partner?.first_name} ${member?.partner?.last_name}`,
    },
  ];

  return (
    <ExpertRecommendationMemberShareWithModal
      isOpen={isOpen}
      onClose={onClose}
      options={options}
      onSubmit={onShareWithPartnerSubmit}
      isLoading={isLoading}
      onChange={onSelectChange}
    />
  );
};

export default WhoShareWithExpertContentPopup;
