import Ably from "ably";

const ABLY_CHANNEL = "webappp_channel";

export const publishExpertContentRecommendationPushNotification = (
  name: string,
  payload: { [key: string]: unknown },
  memberEmail: string,
) => {
  const ablyRest = new Ably.Rest(`${process.env.ABLY_PUBLISH_KEY}`);
  const channel = ablyRest.channels.get(`push:${ABLY_CHANNEL}-${memberEmail}`);

  const extras = {
    push: {
      notification: {
        title: "Expert Content Recommendation",
        body: "Your expert has added a new content recommendation",
        sound: "default",
      },
    },
  };

  channel.publish({ name, data: JSON.stringify(payload), extras });
};
