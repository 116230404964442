import RitualAnalytics from "client-analytics";
import { useEffect } from "react";
import { useGetExpertQueryResolver } from "src/api/resolvers/expertResolvers";
import * as Sentry from "@sentry/react";

export const useAnalyticsIdentify = () => {
  const { data: expert } = useGetExpertQueryResolver();

  useEffect(() => {
    RitualAnalytics.initialize({
      trackClicks: false,
      trackViews: true,
      source: "ritualx2.0",
    });
  }, []);

  useEffect(() => {
    if (!expert) return;
    Sentry.setUser({ id: expert.uuid, email: expert.email });
    Sentry.setContext("expert", expert);

    RitualAnalytics.identify({
      id: expert.uuid,
      email: expert.email,
    });

    window.analytics?.identify(expert.uuid, {
      userId: expert.uuid,
    });
  }, [expert]);
};
