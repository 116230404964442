export interface Expert {
  created: string;
  updated: string;
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  matrix_user: string;
  matrix_pass: string;
  phone_number: string;
  schedule_link?: string;
}

export interface Conversation {
  handle: string;
  id: string;
  subject: string;
  last_message?: {
    body: string;
    text: string;
    created_at: number;
  };
}

export interface SupportMessage {
  body: string;
  created_at: number;
  id: string;
  is_inbound: boolean;
  text: string;
  attachments: string[];
}

export interface BaseMemberInfo {
  first_name: string;
  last_name: string;
  email: string;
  uuid: string;
  phone_number: string;
}

export interface Member extends BaseMemberInfo {
  expert: string;
  partner?: BaseMemberInfo;
}

export interface SendMessageProps {
  expertId: string;
  conversation?: string;
  body: FormData;
}

export type CMSDataBaseType<T> = {
  id: number;
  attributes: {
    createdAt: string;
    publishedAt: string;
    updatedAt: string;
  } & T;
};

export type CMSResponseType<T, isArray = false> = {
  data: isArray extends true ? CMSDataBaseType<T>[] : CMSDataBaseType<T>;
};

export interface SupportTopic {
  label: string;
  value: string;
}

export enum LinkType {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export interface NotificationContent {
  content: string;
  broadcast_id: string;
}

export interface Recommendation {
  id: number;
  title: string;
  description: string;
  contentUrl: string;
  imageUrl: string;
  topic: string;
  format: string;
  tags: string[];
  skills: string[];
  contentShared: boolean;
}

export interface LibraryContent {
  id: number;
  createdAt: string;
  description: string;
  format_tags: CMSResponseType<{ name: string }, true>;
  link: string;
  platform: string;
  publishedAt: string;
  title: string;
  updatedAt: string;
  topics: CMSResponseType<{ title: string }, true>;
  skill_tags: CMSResponseType<{ name: string }, true>;
  format: string;
  content_tags: CMSResponseType<{ name: string }, true>;
  thumbnail: CMSResponseType<{ url: string }, false>;
}

export interface ExpertSharedRecommendation {
  member: string;
  expert: string;
  content_cms_id: string;
}

export interface ExpertRecommendationToShare {
  expert: string;
  member: string;
  content_cms_list: { id: string; title: string }[];
}
