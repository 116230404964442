import { useNavigate } from "react-router-dom";
import { ArrowLeft as BackIcon } from "src/assets/icons/ArrowLeft";
import { Routes } from "src/router/routes";
import styles from "./styles.module.scss";

const BackToHome = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(Routes.HOME);
  };

  return (
    <div className={styles.back_button} onClick={onClick}>
      <BackIcon />
      Back to Home
    </div>
  );
};

export default BackToHome;
