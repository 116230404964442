import seedrandom from "seedrandom";

export const avatarColors = [
  { background: "#CBE1F3", color: "#575961" },
  { background: "#E4DEF8", color: "#575961" },
  { background: "#5081EE", color: "#FFF" },
  { background: "#FAE8FF", color: "#575961" },
  { background: "#DCF3D9", color: "#575961" },
  { background: "#F9EEC5", color: "#3C1480" },
];

export const getUserPicture = (fullName: string) => {
  const rng = seedrandom(fullName);
  const randomInt = Math.abs(rng.int32());
  const randomIndex = randomInt % avatarColors.length;
  return avatarColors[randomIndex];
};
