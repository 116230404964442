export enum SelectType {
  TOPIC = "topic",
  SKILLS = "skills",
  FORMAT = "format",
}

export enum ShareType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  BOTH = "both",
}

export enum ExpertContentRecommendationType {
  SELECT = "select",
  REVIEW = "review",
  VIEW = "view",
}

export interface Recommendation {
  id: number;
  title: string;
  description: string;
  contentUrl: string;
  imageUrl: string;
  topic: string;
  format: string;
  tags: string[];
  skills: string[];
  contentShared: boolean;
}

export type ExpertContentRecommendationPopupType = Exclude<
  ExpertContentRecommendationType,
  ExpertContentRecommendationType.VIEW
>;

export const FILTERS = [
  { type: SelectType.TOPIC, value: "All" },
  { type: SelectType.FORMAT, value: "All" },
  { type: SelectType.SKILLS, value: "All" },
];

export interface FiltersType {
  type: SelectType;
  value: string;
}
