import { ShareType } from "../types";
import { Member } from "../../../../../api/types.ts";
import { SuccessModal } from "ritual-ui/dist/components/composite/SuccessModal";

interface RecommendationSharedPopupProps {
  isOpen: boolean;
  member: Member;
  peopleToShareWith: ShareType;
  onClose: () => void;
}

const RecommendationSharedPopup = ({
  member,
  peopleToShareWith,
  isOpen,
  onClose,
}: RecommendationSharedPopupProps) => {
  const getDescription = () => {
    const name =
      peopleToShareWith === ShareType.BOTH
        ? "both partner's"
        : peopleToShareWith === ShareType.SECONDARY
          ? `${member?.partner?.first_name} ${member?.partner?.last_name}`
          : `${member?.first_name} ${member?.last_name}`;

    return (
      <>
        The pieces you&apos;ve selected have been added to{" "}
        <strong>{name}</strong> recommended content.
      </>
    );
  };

  return (
    <SuccessModal
      isOpen={isOpen}
      title="Recommendation Shared"
      description={getDescription()}
      actionButton={{
        title:
          peopleToShareWith === ShareType.BOTH
            ? "OK"
            : `Back to Library`,
        onClick: onClose,
      }}
    />
  );
};

export default RecommendationSharedPopup;
