import { PageWithBackButton, Typography } from "src/components";
import styles from "./styles.module.scss";
import { Button } from "ritual-ui";
import Filters from "./components/Filters";
import { useGetLibraryContentQuery } from "../../api/instances/cms.ts";
import { ExpertContentRecommendationCard } from "ritual-ui/dist/components/composite/ExpertContentRecommendationCard";
import { ExpertContentRecommendationType } from "ritual-ui/dist/types/expert-recommendation";
import { useEffect, useState } from "react";
import RecommendContentModal from "./components/RecommendContentModal";
import { Member, Recommendation } from "../../api/types.ts";
import ExpertContentRecommendationContent from "./components/ExpertContentRecommendationContent";
import { useContentRecommendationFilter } from "../../hooks/useContentRecommendationFilter.ts";
import { SelectType } from "./components/ExpertContentRecommendationContent/types";
import EmptyScreen from "ritual-ui/dist/components/composite/ExpertContentRecommendationPopup/ui/EmptyScreen";

const ContentForMembers = () => {
  const { data: libraryContent } = useGetLibraryContentQuery();
  const [recommendContentClicked, setRecommendContentClicked] = useState(false);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [
    isExpertContentRecommendationOpenClicked,
    setIsExpertContentRecommendationOpenClicked,
  ] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member>();
  const { selectedFilters, filterChange, clearFilters } =
    useContentRecommendationFilter();

  useEffect(() => {
    if (libraryContent) {
      setRecommendations(libraryContent);
    }
  }, [libraryContent]);

  const onRecommendContinueClick = (member: Member) => {
    setSelectedMember(member);
    setIsExpertContentRecommendationOpenClicked(true);
    setRecommendContentClicked(false);
  };

  const onRecommendContentModalClose = () => {
    setRecommendContentClicked(false);
    setSelectedMember(undefined);
  };

  const onExpertContentRecommendationContentClose = () => {
    setSelectedMember(undefined);
    setIsExpertContentRecommendationOpenClicked(false);
  };

  const onSelectMemberChange = (member: Member) => {
    if (selectedMember?.email === member.email) {
      setSelectedMember(undefined);
    } else {
      setSelectedMember(member);
    }
  };

  const onBackFromExpertContentPopupClick = () => {
    setIsExpertContentRecommendationOpenClicked(false);
    setRecommendContentClicked(true);
  };

  const onFilterSelect = (values: { filter: unknown; type: SelectType }) => {
    const filteredRecommendations = filterChange(values);

    filteredRecommendations && setRecommendations(filteredRecommendations);
  };

  const onClearFiltersClick = () => {
    clearFilters();

    setRecommendations(libraryContent as Recommendation[]);
  };

  return (
    <PageWithBackButton>
      <div className={styles.wrapper}>
        <div className={styles.heading_container}>
          <Typography variant="h2">Content for Members</Typography>
          <Button
            id="recommend_content"
            variant="large"
            className={styles.button}
            onClick={() => setRecommendContentClicked(true)}
          >
            Recommend Content
          </Button>
        </div>
        <Filters
          selectedFilters={selectedFilters}
          onFilterSelect={onFilterSelect}
        />

        <hr className={styles.divider} />

        {recommendations?.length > 0 ? (
          <div className={styles.cards}>
            {recommendations?.map((recommendation) => (
              <div className={styles.card}>
                <ExpertContentRecommendationCard
                  recommendation={recommendation}
                  type={ExpertContentRecommendationType.VIEW}
                  onClick={() => {}}
                />
              </div>
            ))}
          </div>
        ) : (
          <EmptyScreen resetFilters={onClearFiltersClick} />
        )}
      </div>
      <RecommendContentModal
        isOpen={recommendContentClicked}
        onClose={onRecommendContentModalClose}
        onSubmit={onRecommendContinueClick}
        isLoading={false}
        onSelectMember={onSelectMemberChange}
        selectedMember={selectedMember}
      />
      <ExpertContentRecommendationContent
        isOpen={isExpertContentRecommendationOpenClicked}
        onClose={onExpertContentRecommendationContentClose}
        member={selectedMember}
        onBackFromExpertContentPopupClick={onBackFromExpertContentPopupClick}
      />
    </PageWithBackButton>
  );
};

export default ContentForMembers;
