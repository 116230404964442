import { PropsWithChildren } from "react";
import { RitualLogo } from "src/assets/icons";
import BackToHome from "../BackToHome";
import { ProfileMenu } from "../ProfileMenu";
import styles from "./styles.module.scss";

export const Header = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.header}>
      <RitualLogo />
      <BackToHome />
      <div className={styles.tabs}>{children}</div>
      <ProfileMenu />
    </div>
  );
};
