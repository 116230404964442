import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQuery from "../utils/fetchBaseQuery";
import { RootState } from "src/store/types";
import { managementUrls } from "../urls";
import {
  Conversation,
  Expert,
  ExpertRecommendationToShare,
  ExpertSharedRecommendation,
  Member,
  SendMessageProps,
  SupportMessage,
} from "../types";
import { addConversationMessage } from "../queryUpdaters/conversationMessages";

export const managementApi = createApi({
  reducerPath: "managementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.MANAGEMENT_URL,
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).token.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getExpert: builder.query<Expert | null, string>({
      query: managementUrls.experts,
      transformResponse: (response: Expert[], _, email) => {
        return response.find((ex) => ex.email === email) || null;
      },
    }),

    getSupportConversations: builder.query<
      { results: Conversation[]; next_page: string },
      { expertId: string; queryParams: string }
    >({
      query: managementUrls.supportConversations,
    }),

    getConversationMessages: builder.query<
      SupportMessage[],
      { expertId: string; conversation: string }
    >({
      query: managementUrls.conversationMessages,
    }),

    sendSupportMessage: builder.mutation<unknown, SendMessageProps>({
      query: ({ expertId, body }) => ({
        url: managementUrls.sendSupportMessage(expertId),
        method: "POST",
        body,
      }),
      onQueryStarted(args, { dispatch, queryFulfilled }) {
        const dispatchData = addConversationMessage(args);
        if (!dispatchData) return;
        const patchResult = dispatch(dispatchData);
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    getMembers: builder.query<Member[], string>({
      query: managementUrls.members,
    }),

    getMemberSharedRecommendations: builder.query<
      ExpertSharedRecommendation[],
      { expertId: string; memberId: string }
    >({
      query: managementUrls.sharedRecommendations,
    }),

    shareExpertContentRecommendation: builder.mutation<
      unknown,
      ExpertRecommendationToShare
    >({
      query: (body) => ({
        url: managementUrls.shareExpertContentRecommendation(),
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetExpertQuery,
  useGetSupportConversationsQuery,
  useGetConversationMessagesQuery,
  useSendSupportMessageMutation,
  useGetMembersQuery,
  useLazyGetMemberSharedRecommendationsQuery,
  useShareExpertContentRecommendationMutation,
} = managementApi;
