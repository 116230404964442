import { buildUrl } from "./utils/cmsUrlBuilder";

export const managementUrls = {
  experts: () => "experts/",

  supportConversations: ({
    expertId,
    queryParams,
  }: {
    expertId: string;
    queryParams: string;
  }) => `experts/${expertId}/support/conversations/?${queryParams}`,

  conversationMessages: ({
    expertId,
    conversation,
  }: {
    expertId: string;
    conversation: string;
  }) => `experts/${expertId}/support/conversations/${conversation}/messages/`,

  sendSupportMessage: (expertId: string) =>
    `experts/${expertId}/support/send_message/`,

  members: (expertId: string) => `experts/${expertId}/members/`,
  sharedRecommendations: ({
    expertId,
    memberId,
  }: {
    expertId: string;
    memberId: string;
  }) => `expert-recommendations?expert=${expertId}&member=${memberId}`,

  shareExpertContentRecommendation: () =>
    `expert-recommendations/share-expert-recommendation-with-member/`,
};
export const cmsUrls = {
  supportTopics: () => buildUrl("support-topics"),
  notificationContent: (id: string) => buildUrl(`notification-contents/${id}`),
  formatTag: () => buildUrl("format-tags", { populate: ["*"] }),
  topicTag: () => buildUrl("library-topics", { populate: ["*"] }),
  skillTag: () => buildUrl("skill-tags", { populate: ["*"] }),
  libraryContent: () => buildUrl("library-contents", { populate: ["*"] }),
};
