import { LinkType } from "src/api/types";
import { Document, Home, MembersLink, Support } from "src/assets/icons";
import { Routes } from "src/router/routes";
import ContentForMembers from "../../../../assets/icons/ContentForMembers.tsx";

export const links = [
  {
    label: "Home",
    route: Routes.HOME,
    type: LinkType.INTERNAL,
    logo: <Home />,
  },
  {
    label: "Members",
    route: process.env.RITUALX_OLD || "",
    type: LinkType.EXTERNAL,
    logo: <MembersLink />,
  },
  {
    label: "Reports",
    route: `${process.env.RITUALX_OLD}/reports`,
    type: LinkType.EXTERNAL,
    logo: <Document />,
  },
  {
    label: "Content for Members",
    route: Routes.CONTENT_FOR_MEMBERS,
    type: LinkType.INTERNAL,
    logo: <ContentForMembers />,
  },
  {
    label: "Support",
    route: Routes.SUPPORT,
    type: LinkType.INTERNAL,
    logo: <Support />,
  },
];
