import {
  ExpertContentRecommendationPopupType,
  ExpertContentRecommendationType,
  Recommendation,
  ShareType,
} from "./types";
import WhoShareWithExpertContentPopup from "./components/WhoShareWithExpertContentPopup";
import ShareContentWithPartnerPopup from "./components/ShareContentWithPartnerPopup";
import RecommendationSharedPopup from "./components/RecommendationSharedPopup";
import SelectContentToRecommendPopup from "./components/SelectContentToRecommendPopup";
import RitualAnalytics from "client-analytics";
import { OptionType } from "ritual-ui/dist/types/expert-recommendation";
import { publishExpertContentRecommendationPushNotification } from "../../helpers/notifications.ts";
import { Member } from "../../../../api/types.ts";
import { useGetExpertQueryResolver } from "../../../../api/resolvers/expertResolvers.tsx";
import { useShareExpertContentRecommendationMutation } from "../../../../api/instances/management.ts";
import { useGetMembersResolver } from "../../../../api/resolvers/memberResolvers.tsx";
import { useEffect, useState } from "react";

interface ExpertContentRecommendationContentProps {
  isOpen: boolean;
  member?: Member;
  onClose: () => void;
  onBackFromExpertContentPopupClick: () => void;
}

const ExpertContentRecommendationContent = ({
  member,
  isOpen,
  onClose,
  onBackFromExpertContentPopupClick,
}: ExpertContentRecommendationContentProps) => {
  const { data: expert } = useGetExpertQueryResolver();
  const { data: members } = useGetMembersResolver();
  const [shareRecommendation] = useShareExpertContentRecommendationMutation();
  const [selectedMember, setSelectedMember] = useState<Member | undefined>(
    member,
  );
  const [selected, setSelected] = useState<Recommendation[]>([]);
  const [type, setType] = useState<ExpertContentRecommendationPopupType>(
    ExpertContentRecommendationType.SELECT,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isRecommendationShared, setIsRecommendationShared] = useState(false);
  const [confirmToShareWithPartner, setConfirmToShareWithPartner] =
    useState(false);
  const [peopleToShare, setPeopleToShare] = useState(ShareType.BOTH);
  const [recommendContentToPartnerModal, setRecommendContentToPartnerModal] =
    useState(false);

  const onPeopleToShareChange = (value: unknown) => {
    setPeopleToShare((value as OptionType).value as ShareType);
  };

  useEffect(() => {
    setSelectedMember(member);
  }, [member]);

  useEffect(() => {
    sendEvent("expert_contentrecommendation_requested");
  }, []);

  const sendEvent = (
    name: string,
    additionalPayload?: { [key: string]: unknown },
  ) => {
    const payload = {
      expert_uuid: expert?.uuid,
      expert_name: `${expert?.first_name} ${expert?.last_name}`,
      expert_email: expert?.email,
      member_uuid: selectedMember?.uuid,
      timestamp: Date.now(),
      ...additionalPayload,
    };

    window.analytics?.track(name, payload);
    RitualAnalytics.track(name, payload);
  };

  const closeModal = () => {
    sendEvent("expert_contentrecommendation_closed");
    setType(ExpertContentRecommendationType.SELECT);
    setSelected([]);

    onClose();
  };

  const onSubmit = async () => {
    if (!selected.length) {
      setIsLoading(false);
      return;
    }

    if (type === ExpertContentRecommendationType.SELECT) {
      setType(ExpertContentRecommendationType.REVIEW);
      setIsLoading(false);
      return;
    }

    if (selectedMember?.partner) {
      setRecommendContentToPartnerModal(true);
    } else {
      setIsLoading(true);

      try {
        setPeopleToShare(ShareType.PRIMARY);
        await shareRecommendationHandler(
          selectedMember?.uuid || "",
          selectedMember?.email || "",
        );
        setIsRecommendationShared(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const getShareRecommendationPayload = (memberId: string) => {
    return {
      member: memberId,
      expert: expert?.uuid || "",
      content_cms_list: selected.map(({ id, title }) => ({
        id: id.toString(),
        title,
      })),
    };
  };

  const shareRecommendationHandler = async (
    memberId: string,
    memberEmail: string,
  ) => {
    const payload = getShareRecommendationPayload(memberId);
    shareRecommendation(payload);
    sendEvent("expert_content_shared");
    publishExpertContentRecommendationPushNotification(
      "expert_content_recommendation_shared",
      {
        content_cms_list: payload.content_cms_list,
      },
      memberEmail,
    );
    sendEvent("member_contentpush_sent", {
      content_cms_ids_list: payload.content_cms_list,
    });
  };

  const onShareWithPartnerSubmit = async () => {
    try {
      if (selectedMember) {
        if (peopleToShare === ShareType.BOTH && selectedMember.partner) {
          await Promise.all([
            shareRecommendationHandler(
              selectedMember.uuid,
              selectedMember.email,
            ),
            shareRecommendationHandler(
              selectedMember.partner.uuid,
              selectedMember.partner.email,
            ),
          ]);
          setRecommendContentToPartnerModal(false);
          setIsRecommendationShared(true);
        } else if (peopleToShare === ShareType.PRIMARY) {
          await shareRecommendationHandler(
            selectedMember.uuid,
            selectedMember.email,
          );

          setRecommendContentToPartnerModal(false);
          setConfirmToShareWithPartner(true);
        } else if (
          peopleToShare === ShareType.SECONDARY &&
          selectedMember.partner
        ) {
          await shareRecommendationHandler(
            selectedMember.partner.uuid,
            selectedMember.partner.email,
          );

          setRecommendContentToPartnerModal(false);
          setIsRecommendationShared(true);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const changeMember = (newMemberId: string) => {
    const newMember = members?.find((member) => member.uuid === newMemberId);
    setSelectedMember(newMember ?? member);
  };

  return (
    <>
      {selectedMember && (
        <>
          <WhoShareWithExpertContentPopup
            member={selectedMember}
            isOpen={recommendContentToPartnerModal}
            isLoading={isLoading}
            onShareWithPartnerSubmit={onShareWithPartnerSubmit}
            onClose={() => setRecommendContentToPartnerModal(false)}
            onSelectChange={onPeopleToShareChange}
          />
          <ShareContentWithPartnerPopup
            isOpen={confirmToShareWithPartner}
            member={selectedMember}
            onSelectContentClick={() => {
              changeMember(selectedMember?.partner?.uuid || "");
              setSelected([]);
              setConfirmToShareWithPartner(false);
              setPeopleToShare(ShareType.BOTH);
            }}
            onClose={() => {
              setConfirmToShareWithPartner(false);
              setPeopleToShare(ShareType.BOTH);
              closeModal();
            }}
          />
          <RecommendationSharedPopup
            isOpen={isRecommendationShared}
            member={selectedMember}
            onClose={() => {
              setIsRecommendationShared(false);
              closeModal();
            }}
            peopleToShareWith={peopleToShare}
          />
          <SelectContentToRecommendPopup
            isOpen={
              isOpen &&
              !recommendContentToPartnerModal &&
              !isRecommendationShared &&
              !confirmToShareWithPartner
            }
            member={selectedMember}
            expert={expert}
            isLoading={isLoading}
            onClose={closeModal}
            type={type}
            onTypeChange={(type: ExpertContentRecommendationPopupType) =>
              setType(type)
            }
            selected={selected}
            onSubmit={onSubmit}
            setSelected={setSelected}
            secondaryButton={{
              title: "Back",
              onClick: onBackFromExpertContentPopupClick,
            }}
          />
        </>
      )}
    </>
  );
};

export default ExpertContentRecommendationContent;
