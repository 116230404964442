export interface FiltersSelectValueType {
  value: string;
  label: string;
}

export const DEFAULT_FILTER = { value: "All", label: "All" };

export const transformTag = (
  tags?: { name?: string; title?: string }[],
): FiltersSelectValueType[] => {
  const formattedTags =
    tags?.map(({ name, title }) => ({
      value: name || title || "",
      label: name || title || "",
    })) || [];
  return [DEFAULT_FILTER, ...formattedTags];
};
